import React, { useState } from "react";
import { APP_TITLE } from "../constants/constants";
import logoImgPTS from "../../src/assets/images/32.png";

const Header = () => {
	const [isMenuActive, setIsMenuActive] = useState(false);

	const toggleMenu = () => {
		setIsMenuActive(!isMenuActive);
		const nav = document.querySelector(".header-area .nav");
		if (nav) {
			nav.style.display = isMenuActive ? "none" : "block";
		}
	};

	const handleScrollToSection = (event, targetId) => {
		event.preventDefault();
		const target = document.getElementById(targetId);
		if (target) {
			const width = window.innerWidth;
			if (width < 991) {
				setIsMenuActive(false);
				const nav = document.querySelector(".header-area .nav");
				if (nav) {
					nav.style.display = "none";
				}
			}
			window.scrollTo({
				top: target.offsetTop + 1,
				behavior: "smooth",
			});
		}
	};

	return (
		<div>
			<header
				className={`header-area header-sticky wow slideInDown ${
					isMenuActive ? "nav-open" : ""
				}`}
				data-wow-duration="0.75s"
				data-wow-delay="0s"
			>
				<div className="container">
					<div className="row">
						<div className="col-12">
							<nav className="main-nav">
								<a href="#" className="logo">
									<div style={{ display: "flex", alignItems: "center" }}>
										<img
											src={logoImgPTS}
											alt="Logo"
											style={{ width: "50px", marginRight: "10px" }}
										/>
										<h4
											style={{
												display: "inline-block",
												verticalAlign: "middle",
											}}
										>
											{APP_TITLE}{" "}
										</h4>
									</div>
								</a>

								<ul className="nav">
									<li className="scroll-to-section" onClick={toggleMenu}>
										<a href="#top">Home</a>
									</li>
									<li className="scroll-to-section" onClick={toggleMenu}>
										<a href="#services">Services</a>
									</li>
									<li className="scroll-to-section" onClick={toggleMenu}>
										<a href="#technology">Technology</a>
									</li>
									<li className="scroll-to-section" onClick={toggleMenu}>
										<div className="main-red-button">
											<a href="#contact">Contact Now</a>
										</div>
									</li>
								</ul>
								<a className="menu-trigger" onClick={toggleMenu}>
									<span>Menu</span>
								</a>
							</nav>
						</div>
					</div>
				</div>
			</header>
		</div>
	);
};

export default Header;
